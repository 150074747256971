import React from 'react'

import { content } from './content'
import {
  ButtonWrapper,
  Column,
  IntercomWrapper,
  SmallText,
  StyledH3,
  Text,
  Wrapper,
} from './styles'
import { FEATURES } from '../../config'
import { TRACKING_CATEGORIES } from '../../constants/globalStrings'
import GenesysIcon from '../../static/images/icons/genesys-webchat.svg'
import IntercomIcon from '../../static/images/icons/intercom-webchat.svg'
import { PromiseGenesys } from '../../utils/genesys'
import Button from '../Button'

let isMessengerOpened = false

const ChatDetails = () => (
  <IntercomWrapper>
    <Wrapper>
      <Column>
        <img
          src={FEATURES.GENESYS ? GenesysIcon : IntercomIcon}
          alt="Intercom icon"
          data-testid="intercom-icon"
        />
      </Column>
      <Column>
        <StyledH3 data-testid="chat-title">{content.chatTitle}</StyledH3>
        <Text data-testid="email-text">{content.emailText}</Text>
        <ButtonWrapper>
          {FEATURES.GENESYS ? (
            <Button
              level="tertiary"
              data-item-category={TRACKING_CATEGORIES.CONTACT}
              data-item-name={content.dataItemName}
              data-testid="email-button"
              onClick={() => {
                isMessengerOpened
                  ? PromiseGenesys('command', 'Messenger.close', {})
                  : PromiseGenesys('command', 'Messenger.open', {})
                isMessengerOpened = !isMessengerOpened
              }}
            >
              {content.ctaText}
            </Button>
          ) : (
            <Button
              id="intercom_launcher"
              level="tertiary"
              data-item-category={TRACKING_CATEGORIES.CONTACT}
              data-item-name={content.dataItemName}
              data-testid="email-button"
            >
              {content.ctaText}
            </Button>
          )}
        </ButtonWrapper>
        <SmallText>{content.waitTimesAndOutsideOpeningTimes}</SmallText>
      </Column>
    </Wrapper>
  </IntercomWrapper>
)

export default ChatDetails
