import { FEATURES } from '../../config'

const scriptId = 'genesys-script'

;(function () {
  window.toggleGenesys = function () {
    const isLoaded = localStorage.getItem('genesysLoaded') === 'true'

    if (isLoaded) {
      // Remove the Genesys script
      const script = document.getElementById(scriptId)
      const widget = document.getElementById('genesys-messenger')
      if (script && widget) {
        script.remove()
        widget.style.display = 'none'
        console.log('Genesys script removed')
      }
      localStorage.setItem('genesysLoaded', 'false')
    } else {
      loadScript()
    }
  }

  function loadScript() {
    // Load the Genesys script
    ;(function (
      g: any,
      e: string,
      n: string,
      es: any,
      ys: HTMLScriptElement | null
    ) {
      g['_genesysJs'] = e
      g[e] =
        g[e] ||
        function () {
          ;(g[e].q = g[e].q || []).push(arguments)
        }
      // @ts-expect-error genesys script error
      g[e].t = 1 * new Date()
      g[e].c = es
      ys = document.createElement('script')
      ys.id = scriptId
      ys.async = true
      ys.src = n
      ys.charset = 'utf-8'
      document.head.appendChild(ys)
      window.Genesys('subscribe', 'Messenger.ready', function () {
        window.Genesys('command', 'Database.set', {
          messaging: {
            customAttributes: {
              chatURL: document.location.origin + document.location.pathname,
              isAuthenticated: localStorage.getItem('isAuthenticated'),
              entryPoint: 'website',
            },
          },
        })
      })

      console.log('Genesys script loaded')
    })(
      window,
      'Genesys',
      'https://apps.euw2.pure.cloud/genesys-bootstrap/genesys.min.js',
      {
        environment: process.env.GATSBY_GENESYS_ENV,
        deploymentId: process.env.GATSBY_GENESYS_DEPLOYMENT_ID,
      },
      null
    )

    localStorage.setItem('genesysLoaded', 'true')
  }

  function initGenesys() {
    console.log('Loading Genesys', localStorage.getItem('genesysLoaded'))
    loadScript()
  }

  // Initialize Genesys on window load
  window.onload = function () {
    if (FEATURES.GENESYS && localStorage.getItem('genesysLoaded') !== 'false') {
      initGenesys()
    }
  }
})()
